import React, { ReactNode } from 'react';
import {
  Caption,
  Colors,
  Link,
  MainAxisAlignments,
  Paragraph,
  Row,
  Sizes,
  Spacer,
  Spacings,
  Text,
  TextButton
} from '@polestar/component-warehouse-react';
import * as Styled from '~root/src/app/views/OrdersView/styles';
import { HandoverTableData, MarketAdaptionStates } from '~app/apollo/queries/getHandoverExtended';
import {
  capitalizeFirstLetter,
  displayText,
  isNonEmptyString,
  isNullishOrEmpty
} from '~app/helpers/stringHelper';
import { convertFromUtc, formatDate, isDateInTheFuture } from '~app/helpers/dateHelper';
import { getDeRegistrationInformationStatusKey } from '~app/helpers/orderHelper';
import {
  BookingStatuses,
  EN_DASH,
  ExtrasOrdered,
  FinalPaymentBuyerStatuses,
  HandoverMethods,
  HandoverPreparationStatuses,
  SingleOptionsStatuses,
  VehiclePreparationStatuses
} from '~app/enums';
import { TableCells } from '~app/enums/OrderTable';
import { extrasOrderStatus, isBulkOrder, supportsEssential } from '~app/helpers/handoverHelper';
import { InternalAvatar, InternalColors } from '@polestar/component-warehouse-react/internal';
import { Text14 } from '~app/helpers/styles';
import useDatoState from './useDatoState';
import useMarketState from './useMarketState';
import {
  arrowRightIcon,
  calendarCrossIcon,
  checkIcon,
  COMPLETED_CELL,
  DEFAULT_CELL,
  DISABLED_CELL,
  IN_PROGRESS_CELL,
  lockedIcon,
  markerIcon,
  minusIcon,
  NOT_APPLICABLE_CELL,
  NOT_ORDERED_CELL,
  NOT_STARTED_CELL,
  OrderCell,
  ORDERED_CELL,
  OrderTableCell,
  pendingIcon,
  refreshIcon,
  unlockedIcon,
  warningIcon
} from '../enums/Cells';
import useTimezone from './useTimezone';
import useAuthState from './useAuthState';
import {
  GetLocationsResponse,
  GET_LOCATIONS,
  GetLocationParams
} from '../apollo/queries/getLocations';
import { useQuery } from '@apollo/client';
import useDrawerState from './useDrawerState';
import NotesContextProvider from '../contexts/Notes';
import { cloneDeep } from 'lodash';
import Notes from '../components/Shared/Notes';
import * as SharedStyle from '~app/components/Shared/styles';
import { RegistrationState } from '~enums/Filters';
import AdditionalUpgradesDrawerContent from '../components/Shared/AdditionalUpgradesDrawerContent';
import ExtrasDrawerContent from '~root/src/app/components/Shared/ExtrasDrawerContent';
import DataChip from '~components/Shared/DataChip';
import {
  getExtraStatus,
  getTowbarRelatedParts,
  getVehiclePreparationExtras
} from '~helpers/extrasHelper';
import { getAdditionalUpgradesStatus } from '~helpers/additionalUpgradesHelper';

export interface UseTableColumnsType {
  getColumnCell: (name: string, handover?: HandoverTableData) => OrderTableCell;
  renderCell: (col: OrderCell, expandCells: boolean, handover?: HandoverTableData) => ReactNode;
}

const useTableColumns = (userLanguage: string, handoverProp?: HandoverTableData) => {
  const { text } = useDatoState();
  const { countries } = useMarketState();
  const {
    handoverOrderTimezone,
    formatDateUtcIsoStringByLangTimezone,
    formatTimeUtcIsoStringByTimezone
  } = useTimezone();
  const { userLocale } = useAuthState();
  const { data: allLocations } = useQuery<GetLocationsResponse, GetLocationParams>(GET_LOCATIONS, {
    variables: {
      input: {
        isBackwardsQuery: false,
        paginationToken: null
      }
    },
    onError: e => console.error(e)
  });
  const { openDrawer, handleClose } = useDrawerState();

  const getColumnCell = (name: string, handover?: HandoverTableData) => {
    const handoverToUse = handover ?? handoverProp;
    if (handoverToUse === undefined) {
      return;
    }
    //TODO: After deploying to prod, remove this and change the original Registration column in dato
    if (name === 'RegistrationStatus') name = 'Registration';
    let cell: OrderTableCell;
    switch (name) {
      case TableCells.CUSTOMER_CELL:
        cell = getCustomer(handoverToUse);
        break;
      case TableCells.VIN_CELL:
        cell = getVin(handoverToUse);
        break;
      case TableCells.REGISTRATION_STATUS_CELL:
        cell = getRegistration(handoverToUse);
        break;
      case TableCells.REGISTRATION_CELL:
        cell = getRegistration(handoverToUse);
        break;
      case TableCells.REGISTRATION_NUMBER_CELL:
        cell = getRegNumber(handoverToUse);
        break;
      case TableCells.REGISTRATION_DATE_CELL:
        cell = getRegDate(handoverToUse);
        break;
      case TableCells.HANDOVER_TIME_SLOT_CELL:
        cell = getHandoverTimeSlot(handoverToUse);
        break;
      case TableCells.BOOKING_STATUS_CELL:
        cell = getBookingStatus(handoverToUse);
        break;
      case TableCells.VEHICLE_PREPARATION_STATE_CELL:
        cell = getVehiclePreparationStatus(handoverToUse);
        break;
      case TableCells.PDI_LABEL_SHORT_CELL:
        cell = getPdiStatus(handoverToUse);
        break;
      case TableCells.EXTRAS_STATUS_CELL:
        cell = getExtrasOrderStatus(handoverToUse);
        break;
      case TableCells.ADDITIONAL_UPGRADES_COUNT_CELL:
        cell = getAdditionalUpgradesCount(handoverToUse);
        break;
      case TableCells.EXTRAS_CELL:
        cell = getExtrasCount(handoverToUse);
        break;
      case TableCells.PDS_LABEL_SHORT_CELL:
        cell = getPdsStatus(handoverToUse);
        break;
      case TableCells.ASSIGNED_TO_CELL:
        cell = getAssignedTo(handoverToUse);
        break;
      case TableCells.ASSIGNED_TO_USER_ID_CELL:
        cell = getAssignedToUserId(handoverToUse);
        break;
      case TableCells.LOCK_STATE_CELL:
        cell = getLockState(handoverToUse);
        break;
      case TableCells.FINANCE_METHOD_CELL:
        cell = getFinanceMethod(handoverToUse);
        break;
      case TableCells.ORDER_TYPE_CELL:
        cell = getOrderType(handoverToUse);
        break;
      case TableCells.CHARGING_CELL:
        cell = getCharging(handoverToUse);
        break;
      case TableCells.DELIVERY_LOCATION_CELL:
        cell = getHandoverLocationName(handoverToUse);
        break;
      case TableCells.PDI_PDS_LOCATION_CELL:
        cell = getPdiPdsLocationName(handoverToUse);
        break;
      case TableCells.ORDER_ID_CELL:
        cell = getOrderId(handoverToUse);
        break;
      case TableCells.HANDOVER_METHOD_CELL:
        cell = getHandoverMethod(handoverToUse);
        break;
      case TableCells.ESTIMATED_ARRIVAL_DATE_CELL:
        cell = getArrivalDate(handoverToUse);
        break;
      case TableCells.DEFAULT_BRAND_STATUS_CELL:
        cell = getDefaultBrandStatus(handoverToUse);
        break;
      case TableCells.VISTA_BRAND_STATUS_UPDATED_AT_CELL:
        cell = getVistaStatusUpdatedAt(handoverToUse);
        break;
      case TableCells.MARKET_ADAPTION_CELL:
        cell = getMarketAdaption(handoverToUse);
        break;
      case TableCells.MARKET_ADAPTION_DATE_CELL:
        cell = getMarketAdaptionDate(handoverToUse);
        break;
      case TableCells.EMEA_BRAND_STATUS_CELL:
        cell = getEmeaBrandStatus(handoverToUse);
        break;
      case TableCells.FULLY_PAID_CELL:
        cell = getFullyPaid(handoverToUse);
        break;
      case TableCells.REG_RESPONSIBILITY_CELL:
        cell = getRegResponsibility(handoverToUse);
        break;
      case TableCells.REGISTRATION_PARTNER_STATUS_CELL:
        cell = getRegistrationPartnerStatus(handoverToUse);
        break;
      case TableCells.MARKET_CELL:
        cell = getMarket(handoverToUse);
        break;
      case TableCells.ALERTS_CELL:
        cell = getAlerts(handoverToUse);
        break;
      case TableCells.NOTES_COUNT_CELL:
        cell = getNotesCount(handoverToUse);
        break;
      case TableCells.NOTES_COMMENTS_CELL:
        cell = getNotesComments(handoverToUse);
        break;
      case TableCells.HANDOVER_TIMEZONE:
        cell = getHandoverTimezone(handoverToUse);
        break;
      case TableCells.BATCH_ID_CELL:
        cell = getBatchId(handoverToUse);
        break;
      case TableCells.PICKUP_DATE_CELL:
        cell = getPickupDate(handoverToUse);
        break;
      case TableCells.CONTACT_PERSON_CELL:
        cell = getContactPerson(handoverToUse);
        break;
      case TableCells.EDITION:
        cell = getEdition(handoverToUse);
        break;
      case TableCells.BUYER:
        cell = getBuyer(handoverToUse);
        break;
      case TableCells.HANDOVER_PREPARATION_CELL:
        cell = getHandoverPreparationStatus(handoverToUse);
        break;
      case TableCells.FEEDBACK_COMMENT:
        cell = getFeedbackComment(handoverToUse);
        break;
      case TableCells.HAS_THUMBS_UP:
        cell = getHasThumbsUp(handoverToUse);
        break;
      case TableCells.COMPLETED_AT:
        cell = getCompletedAt(handoverToUse);
        break;
      case TableCells.COMMERCIAL_DELIVERY_HOLD_STATUS:
        cell = getCommercialDeliveryHoldStatus(handoverToUse);
        break;
      case TableCells.MODEL:
        cell = getModel(handoverToUse);
        break;
      case TableCells.MODEL_YEAR:
        cell = getModelYear(handoverToUse);
        break;
      case TableCells.PO_NUMBER:
        cell = getPoNumber(handoverToUse);
        break;
      case TableCells.EARLIEST_ALLOWED_HANDOVER_DATE:
        cell = getEarliestAllowedHandoverDate(handoverToUse);
        break;
      case TableCells.SINGLE_OPTIONS_STATUS:
        cell = getSingleOptionsStatus(handoverToUse);
        break;
      default:
        cell = DEFAULT_CELL;
        break;
    }
    return cell;
  };

  const renderCell = (col: OrderCell, expandCells = true, handover?: HandoverTableData) => {
    const handoverToUse = handover ?? handoverProp;
    if (handoverToUse === undefined) {
      console.error('No handover is defined');
      return;
    }

    const cell: OrderTableCell = getColumnCell(col.name, handoverToUse) ?? DEFAULT_CELL;
    return <DataChip cell={cell} expandCell={expandCells} />;
  };

  const getCustomer = (handover: HandoverTableData) => {
    let name = handover.customer?.firstName + ' ' + handover.customer?.lastName;
    const isBulk =
      handover.orderData?.handoverMethod === HandoverMethods.BULK_COLLECT ||
      handover.orderData?.handoverMethod === HandoverMethods.BULK_CUSTOMER_DELIVERY;

    if (isBulk) {
      name =
        handover.orderData?.roleHolders?.find(role => role.roles.includes('owner'))?.name ?? '-';
    }
    return {
      value: name,
      valueString: name
    };
  };

  const getRegResponsibility = (handover: HandoverTableData) => {
    const useRegistrationAgency = handover.orderData?.useRegistrationAgency;

    const label =
      useRegistrationAgency === null ? EN_DASH : useRegistrationAgency ? 'Agency' : 'Customer';
    return {
      value: label,
      valueString: label
    };
  };

  const getFullyPaid = (handover: HandoverTableData) => {
    const status = handover.orderData?.finalPaymentBuyerStatus;

    const isFleetOrder = handover.orderData?.orderType?.toLowerCase() === 'fleet';

    const label = isFleetOrder
      ? 'N/A'
      : status === FinalPaymentBuyerStatuses.FULLY_PAID
      ? text('Yes')
      : text('No');
    return {
      value: label,
      valueString: label
    };
  };

  const getCharging = (handover: HandoverTableData) => {
    let cell: OrderTableCell;

    const chargingDone = handover.preparations?.find(x => x.preparationId === 'charge');

    if (chargingDone) {
      cell = COMPLETED_CELL;
    } else {
      cell = NOT_STARTED_CELL;
    }

    return cell;
  };

  const getRegistrationPartnerStatus = (handover: HandoverTableData) => {
    if (handover.marketId?.toLowerCase() !== 'de') {
      return NOT_APPLICABLE_CELL;
    }

    const deRegStatus = getDeRegistrationInformationStatusKey(
      handover.orderData?.deSpeedRegistrationStatus
    );
    return {
      value: deRegStatus ? text(deRegStatus) : EN_DASH,
      valueString: deRegStatus ? text(deRegStatus) : EN_DASH
    };
  };

  const getMarketAdaption = (handover: HandoverTableData) => {
    return handover.marketAdaptionState === MarketAdaptionStates.COMPLETED
      ? {
          value: text('StatusCompleted'),
          valueString: text('StatusCompleted'),
          icon: checkIcon,
          borderColor: InternalColors.systemGreen
        }
      : handover.marketAdaptionState === MarketAdaptionStates.IN_PROGRESS
      ? {
          value: text('StatusInProgress'),
          valueString: text('StatusInProgress'),
          icon: refreshIcon,
          borderColor: InternalColors.systemYellow
        }
      : {
          value: text('StatusNotStarted'),
          valueString: text('StatusNotStarted'),
          icon: minusIcon,
          borderColor: Colors.agatheGrey
        };
  };

  const getEmeaBrandStatus = (handover: HandoverTableData) => {
    const vista = handover.orderData?.actualBrandStatusPointNumber;
    let value = vista ? text(`VistaBrand-${vista.toString()}`) : EN_DASH;
    let borderColor: InternalColors | Colors = Colors.agatheGrey;
    let icon;

    switch (vista) {
      case 11200: // Factory complete
      case 16005: // COFT
        icon = minusIcon;
        borderColor = Colors.black;
        break;
      case 14036: // Despatched to Shanghai
      case 14080: // Departed to Zeebrugge
      case 14082: // Departed to Zeebrugge
      case 16000: // In transit to dealer
      case 16080: // In transit to dealer
        icon = arrowRightIcon;
        borderColor = InternalColors.systemYellow;
        break;
      case 14041: // Arrived Shanghai
      case 14090: // Arrived Zeebrugge
      case 15500: // Arrived importer
      case 16010: // Arrived VPC
      case 16025: // Arrived to port of loading
      case 16026: // Arrived to buffer Area
      case 16040: // Arrived NSC stock
      case 16500: // Arrived dealer
        icon = markerIcon;
        borderColor = InternalColors.systemGreen;
        break;
      case 16020: // Compound ready
      case 16022: // Ready for Transport
      case 16030: // Departed to NSC stock
        icon = checkIcon;
        borderColor = InternalColors.systemGreen;
        break;
      default:
        value = displayText(vista?.toString());
    }
    return {
      value: value,
      valueString: value,
      borderColor: borderColor,
      icon: icon
    };
  };

  const getDefaultBrandStatus = (handover: HandoverTableData) => {
    const vista = handover.orderData?.actualBrandStatusPointNumber;
    let value = vista ? text(`VistaBrand-${vista.toString()}`) : EN_DASH;
    let borderColor: InternalColors | Colors = Colors.agatheGrey;
    let icon;

    switch (vista) {
      case 3000:
      case 3500:
      case 4000:
      case 11000:
        icon = minusIcon;
        borderColor = Colors.agatheGrey;
        break;
      case 11200:
      case 11207:
      case 11216:
        icon = minusIcon;
        borderColor = Colors.agatheGrey;
        break;
      case 14036:
      case 14080:
      case 14082:
      case 14089:
      case 14091:
      case 14095:
      case 16030:
      case 16032:
      case 15550:
      case 16000:
      case 16080:
        icon = arrowRightIcon;
        borderColor = InternalColors.systemYellow;
        break;
      case 16005:
        icon = arrowRightIcon;
        borderColor = InternalColors.systemYellow;
        break;
      case 16020:
        icon = checkIcon;
        borderColor = InternalColors.systemYellow;
        break;
      case 16040:
        icon = markerIcon;
        borderColor = InternalColors.systemYellow;
        break;
      case 14041:
      case 14090:
      case 15500:
      case 16010:
      case 16025:
      case 16026:
        icon = markerIcon;
        borderColor = InternalColors.systemYellow;
        break;
      case 15560:
        icon = pendingIcon;
        borderColor = InternalColors.systemYellow;
        break;
      case 16022:
        icon = checkIcon;
        borderColor = InternalColors.systemYellow;
        break;
      case 16070:
      case 15590:
      case 17500:
      case 17900:
        icon = checkIcon;
        borderColor = InternalColors.systemGreen;
        break;
      case 16500:
        icon = markerIcon;
        borderColor = InternalColors.systemGreen;
        break;
      case 17700:
        icon = pendingIcon;
        borderColor = InternalColors.systemGreen;
        break;
      case 19000:
        icon = warningIcon;
        borderColor = Colors.error;
        break;
      default:
        value = displayText(vista?.toString());
    }

    return {
      value: value,
      valueString: value,
      borderColor: borderColor,
      icon: icon
    };
  };

  const getVistaStatusUpdatedAt = (handover: HandoverTableData) => {
    const updatedAt = handover.orderData?.actualBrandStatusDate
      ? formatDate(
          convertFromUtc(
            handover.orderData?.actualBrandStatusDate,
            handoverOrderTimezone(handover.locationId)
          ),
          userLanguage,
          'DD MMM, YYYY'
        )
      : EN_DASH;
    return {
      value: updatedAt,
      valueString: updatedAt
    };
  };

  const getVin = (handover: HandoverTableData) => {
    const vin = displayText(handover.vin);
    return {
      value: vin,
      valueString: vin
    };
  };

  const getRegNumber = (handover: HandoverTableData) => {
    const regNumber = displayText(handover.orderData?.vehicleRegistrationNumber);
    return { value: regNumber, valueString: regNumber };
  };
  const getRegDate = (handover: HandoverTableData) => {
    const regDate = handover.orderData?.registrationDate
      ? formatDate(
          convertFromUtc(
            handover.orderData?.registrationDate,
            handoverOrderTimezone(handover.locationId)
          ),
          userLanguage,
          'DD MMM, YYYY'
        )
      : EN_DASH;

    return { value: regDate, valueString: regDate };
  };

  const getFinanceMethod = (handover: HandoverTableData) => {
    const financeMethod = handover.orderData?.financeMethod
      ? text('FINANCE_TYPE_' + capitalizeFirstLetter(handover.orderData.financeMethod))
      : EN_DASH;
    return { value: financeMethod, valueString: financeMethod };
  };

  const getOrderType = (handover: HandoverTableData) => {
    const isPreOrderOrExtrasOrder =
      handover.orderData?.orderType === 'pre-order' ||
      handover.orderData?.orderType === 'extras-order';
    const orderType = capitalizeFirstLetter(
      displayText(isPreOrderOrExtrasOrder ? '' : handover.orderData?.orderType)
    );
    return { value: orderType, valueString: orderType };
  };

  const getHandoverLocationName = (handover: HandoverTableData) => {
    const handoverLocationName = displayText(
      allLocations?.locations?.locations.find(
        loc => loc.id === handover.orderData?.handoverLocation?.code
      )?.name ?? handover.orderData?.handoverLocation?.name
    );
    return { value: handoverLocationName, valueString: handoverLocationName };
  };

  const getPdiPdsLocationName = (handover: HandoverTableData) => {
    const PdiPdsLocationName = displayText(
      allLocations?.locations?.locations.find(
        loc => loc.id === handover.orderData?.pdiPdsLocation?.code
      )?.name ?? handover.orderData?.handoverLocation?.name
    );
    return { value: PdiPdsLocationName, valueString: PdiPdsLocationName };
  };

  const getOrderId = (handover: HandoverTableData) => {
    const orderId = displayText(handover.orderNumber);
    return { value: orderId, valueString: orderId };
  };

  const getHandoverMethod = (handover: HandoverTableData) => {
    let handoverMethod = EN_DASH;
    if (supportsEssential(handover.marketId ?? '', handover.orderData?.handoverMethod ?? '')) {
      handoverMethod = text(`HANDOVER_METHOD_${handover.orderData?.handoverMethod?.toUpperCase()}`);
    } else {
      handoverMethod = capitalizeFirstLetter(displayText(handover.orderData?.handoverMethod));
    }
    return { value: handoverMethod, valueString: handoverMethod };
  };

  const getHandoverTimezone = (handover: HandoverTableData) => {
    return {
      value: handoverOrderTimezone(handover.locationId),
      valueString: handoverOrderTimezone(handover.locationId)
    };
  };

  const getArrivalDate = (handover: HandoverTableData) => {
    const arrivalDate = handover.estimatedArrival
      ? formatDate(
          convertFromUtc(handover.estimatedArrival, handoverOrderTimezone(handover.locationId)),
          userLanguage,
          'DD MMM, YYYY'
        )
      : EN_DASH;
    return {
      value: arrivalDate,
      valueString: arrivalDate,
      fontColor: isDateInTheFuture(arrivalDate) ? Colors.black : Colors.stormGrey
    };
  };

  const getDateAndTimeString = (
    startDate?: string | null,
    endDate?: string | null,
    locationId?: string | null
  ) => {
    if (isNonEmptyString(startDate) && isNonEmptyString(endDate)) {
      return `${formatDateUtcIsoStringByLangTimezone(
        startDate,
        handoverOrderTimezone(locationId),
        userLanguage,
        'MMM D'
      )}, ${formatTimeUtcIsoStringByTimezone(
        startDate,
        handoverOrderTimezone(locationId),
        userLocale
      )} - ${formatTimeUtcIsoStringByTimezone(
        endDate,
        handoverOrderTimezone(locationId),
        userLocale
      )}`;
    }
    return '';
  };

  const getHandoverTimeSlot = (handover: HandoverTableData) => {
    let status;
    let firstTimeSlot;
    let secondTimeSlot;
    if (isBulkOrder(handover)) {
      status = BookingStatuses.CONFIRMED;
      firstTimeSlot = getDateAndTimeString(
        handover.batch?.scheduledHandoverTime?.start,
        handover.batch?.scheduledHandoverTime?.end,
        handover.locationId
      );
    } else {
      status = handover.booking?.bookingStatus;
      firstTimeSlot = getDateAndTimeString(
        handover.booking?.handoverDate,
        handover.booking?.handoverEndDate,
        handover.locationId
      );
      secondTimeSlot = getDateAndTimeString(
        handover.booking?.handoverSecondaryDate,
        handover.booking?.handoverSecondaryEndDate,
        handover.locationId
      );
    }

    const fontColor = status === 'DECLINED' || status === 'CANCELLED' ? Colors.error : Colors.black;
    const secondTimeSlotStyle = {
      color: fontColor,
      fontSize: status === 'CONFIRMED' ? '12px' : '14px',
      textDecoration: status === 'CONFIRMED' ? 'line-through' : undefined
    };

    return {
      value: (
        <>
          {status && status !== 'SCHEDULE' && status !== 'DISABLED' ? (
            <>
              <Text14 color={fontColor}>{firstTimeSlot}</Text14>
              {handover.booking?.handoverSecondaryDate &&
                handover.booking?.handoverSecondaryEndDate && (
                  <>
                    <br />
                    <Text style={secondTimeSlotStyle}>{secondTimeSlot}</Text>
                  </>
                )}
            </>
          ) : (
            EN_DASH
          )}
        </>
      ),
      valueString: `${firstTimeSlot}${
        status !== 'CONFIRMED' && isNonEmptyString(secondTimeSlot) ? ' | ' + secondTimeSlot : ''
      }`
    };
  };

  const getBookingStatus = (handover: HandoverTableData) => {
    const bookingStatus = handover.booking?.bookingStatus || '';
    let bookingStatusValue = bookingStatus;

    let cell: OrderTableCell;
    switch (bookingStatus) {
      case 'CONFIRMED':
        cell = {
          icon: checkIcon,
          borderColor: InternalColors.systemGreen,
          value: 'Confirmed',
          valueString: 'Confirmed'
        };
        break;
      case 'PENDING':
        cell = {
          icon: pendingIcon,
          borderColor: InternalColors.systemYellow,
          value: 'Pending',
          valueString: 'Pending'
        };
        break;
      case 'DECLINED':
        cell = {
          icon: calendarCrossIcon,
          borderColor: Colors.error,
          value: 'Declined',
          valueString: 'Declined'
        };
        break;
      case 'DISABLED':
        cell = DISABLED_CELL;
        break;
      case 'SCHEDULE':
      case 'CANCELLED':
      case 'REMOVED':
        cell = {
          icon: minusIcon,
          borderColor: Colors.agatheGrey,
          value: 'Not booked',
          valueString: 'Not booked'
        };
        break;
      default:
        cell = {
          value: bookingStatusValue,
          valueString: bookingStatus,
          icon: minusIcon,
          borderColor: Colors.agatheGrey
        };
        break;
    }

    return cell;
  };

  const getVehiclePreparationStatus = (handover: HandoverTableData) => {
    let cell: OrderTableCell;

    switch (handover.vehiclePreparationState) {
      case VehiclePreparationStatuses.NOT_APPLICABLE:
        cell = NOT_APPLICABLE_CELL;
        break;
      case VehiclePreparationStatuses.NOT_STARTED:
        cell = NOT_STARTED_CELL;
        break;
      case VehiclePreparationStatuses.IN_PROGRESS:
        cell = IN_PROGRESS_CELL;
        break;
      case VehiclePreparationStatuses.COMPLETED:
        cell = COMPLETED_CELL;
        break;
      default:
        cell = NOT_STARTED_CELL;
    }

    return cell;
  };

  const getPdiStatus = (handover: HandoverTableData) => {
    let cell: OrderTableCell;

    const isRemarketed = handover.orderData?.orderType === 'remarketed';
    const pdiCompleted = handover.preparations?.find(x => x.preparationId === 'pdi');

    if (isRemarketed) {
      cell = NOT_APPLICABLE_CELL;
    } else if (pdiCompleted) {
      cell = COMPLETED_CELL;
    } else {
      cell = NOT_STARTED_CELL;
    }

    return cell;
  };

  const getExtrasOrderStatus = (handover: HandoverTableData) => {
    if (handover.orderData?.orderType === 'remarketed') return NOT_APPLICABLE_CELL;
    if (handover.orderData?.lockState !== 'locked')
      return {
        value: 'Awaiting order lock',
        valueString: 'Awaiting order lock',
        icon: pendingIcon,
        borderColor: InternalColors.systemYellow
      };

    switch (extrasOrderStatus(handover)) {
      case ExtrasOrdered.ALL_COMPLETED:
        return COMPLETED_CELL;
      case ExtrasOrdered.ALL_ORDERED:
        return ORDERED_CELL;
      case ExtrasOrdered.NOT_ORDERED:
        return NOT_ORDERED_CELL;
      case ExtrasOrdered.PARTLY_ORDERED:
        return IN_PROGRESS_CELL;
      case ExtrasOrdered.NOTHING_TO_ORDER:
        return NOT_APPLICABLE_CELL;
      default:
        return { value: EN_DASH, valueString: EN_DASH };
    }
  };

  const getSingleOptionsStatus = (handover: HandoverTableData) => {
    const combinedStatus = getAdditionalUpgradesStatus(handover);

    switch (combinedStatus) {
      case SingleOptionsStatuses.NOT_ORDERED:
        return NOT_ORDERED_CELL;
      case SingleOptionsStatuses.IN_PROGRESS:
        return IN_PROGRESS_CELL;
      case SingleOptionsStatuses.ORDERED:
        return ORDERED_CELL;
      case SingleOptionsStatuses.COMPLETED:
        return COMPLETED_CELL;
      default:
        return DEFAULT_CELL;
    }
  };

  const getAdditionalUpgradesCount = (handover: HandoverTableData) => {
    const additionalUprades = handover.orderData?.additionalUpgrades ?? [];

    return {
      value: (
        <Styled.CenterItems>
          {additionalUprades?.length > 0 && <SharedStyle.NumberCircleBlack />}
          <TextButton
            onClick={() =>
              openDrawer(
                <AdditionalUpgradesDrawerContent handover={handover} onClose={handleClose} />
              )
            }
          >
            <Paragraph>{additionalUprades.length.toString()}</Paragraph>
          </TextButton>
        </Styled.CenterItems>
      ),
      icon: undefined,
      borderColor: 'inherit',
      valueString:
        additionalUprades.length > 0
          ? additionalUprades.map(({ code }) => code).join('; ')
          : EN_DASH
    };
  };

  const getExtrasCount = (handover: HandoverTableData) => {
    const extras = getVehiclePreparationExtras(handover);

    switch (extrasOrderStatus(handover)) {
      case ExtrasOrdered.ALL_COMPLETED:
      case ExtrasOrdered.ALL_ORDERED:
      case ExtrasOrdered.NOT_ORDERED:
      case ExtrasOrdered.PARTLY_ORDERED:
        return {
          value: (
            <Styled.CenterItems>
              {extras?.length > 0 && <SharedStyle.NumberCircleBlack />}
              <TextButton
                onClick={() =>
                  openDrawer(<ExtrasDrawerContent handover={handover} extras={extras} />)
                }
              >
                <Paragraph>{handover.extraPackages?.length}</Paragraph>
              </TextButton>
            </Styled.CenterItems>
          ),
          icon: undefined,
          borderColor: 'inherit',
          valueString: `${extras
            .map(
              extra =>
                `${extra.partNumber} - ${
                  getExtraStatus(handover, extra).valueString
                } - ${extra.fallbackName?.replaceAll('"', '')}\n${
                  extra.products &&
                  `Parts: ${[
                    ...extra.products,
                    ...getTowbarRelatedParts(
                      handover.orderData?.modelYear ?? '',
                      handover.orderData?.chassisNumber ?? '',
                      extra.partNumber ?? ''
                    ).map(x => ({
                      partNumber: x.article_number
                    }))
                  ]
                    .map(product => product.partNumber)
                    .join('; ')}`
                }
                `
            )
            .join('\n')}`
        };

      case ExtrasOrdered.NOTHING_TO_ORDER:
        return NOT_APPLICABLE_CELL;
      default:
        return { value: EN_DASH, valueString: EN_DASH };
    }
  };

  const getPdsStatus = (handover: HandoverTableData) => {
    let cell: OrderTableCell;

    const isRemarketed = handover.orderData?.orderType === 'remarketed';
    const pdsCompleted = handover.preparations?.find(x => x.preparationId === 'pds') ? true : false;

    if (isRemarketed) {
      cell = NOT_APPLICABLE_CELL;
    } else if (pdsCompleted) {
      cell = COMPLETED_CELL;
    } else {
      cell = NOT_STARTED_CELL;
    }

    return cell;
  };

  const getRegistration = (handover: HandoverTableData) => {
    let cell: OrderTableCell;

    switch (handover.vehicleRegistrationState) {
      case RegistrationState.COMPLETED:
        cell = COMPLETED_CELL;
        break;
      case RegistrationState.IN_PROGRESS:
        cell = IN_PROGRESS_CELL;
        break;
      default:
        cell = NOT_STARTED_CELL;
    }

    return cell;
  };

  const getLockState = (handover: HandoverTableData) => {
    let cell: OrderTableCell;
    const status = handover.orderData?.lockState;
    switch (status) {
      case 'locked':
        cell = {
          value: 'Locked',
          valueString: 'Locked',
          icon: lockedIcon,
          borderColor: InternalColors.systemGreen
        };
        break;
      case 'unlocked':
        cell = {
          value: 'Not locked',
          valueString: 'Not locked',
          icon: unlockedIcon,
          borderColor: Colors.black
        };
        break;
      case 'manually_unlocked':
        cell = {
          value: 'Manually unlocked',
          valueString: 'Manually unlocked',
          icon: warningIcon,
          borderColor: Colors.error
        };
        break;
      default:
        cell = NOT_APPLICABLE_CELL;
        break;
    }

    return cell;
  };

  const getAssignedTo = (handover: HandoverTableData) => {
    let name = handover.assignedToUserName ?? EN_DASH;
    let initials = EN_DASH;
    if (isNonEmptyString(name)) {
      if (name.includes(',')) {
        let nameArr = name.replace(' ', '').split(',');
        initials = (nameArr[1].substring(0, 1) + nameArr[0].substring(0, 1)).toUpperCase();
      } else if (name.includes('Admin')) {
        let nameArr = name.replace(/Admin/, '').replace(' - ', '').split(' ');
        initials = (nameArr[0].substring(0, 1) + nameArr[1].substring(0, 1)).toUpperCase();
      } else {
        initials = name[0];
      }
    }
    return {
      value: (
        <InternalAvatar
          style={{ maxHeight: '32px' }}
          size={Sizes.small}
          initials={initials}
          disabled={false}
          static
        />
      ),
      valueString: name
    };
  };
  const getAssignedToUserId = (handover: HandoverTableData) => {
    const val = handover.assignedToUserProfileId ?? EN_DASH;
    return {
      value: val,
      valueString: val
    };
  };

  const getMarket = (handover: HandoverTableData) => {
    const market = countries?.find(country => country.id === handover.marketId)?.name;

    return {
      value: displayText(market),
      valueString: displayText(market)
    };
  };

  const getAlerts = (handover: HandoverTableData) => {
    const alerts = handover.orderData?.commercialQualityHoldStatus ? text('QualityHold') : EN_DASH;
    return {
      value: alerts,
      valueString: alerts
    };
  };

  const getNotesCount = (handover: HandoverTableData) => {
    const notesCount = handover.notesCount;
    return {
      value: (
        <Styled.CenterItems>
          {notesCount !== 0 && <SharedStyle.NumberCircleBlack />}
          <TextButton
            onClick={() =>
              openDrawer(
                <NotesContextProvider
                  key={handover.orderNumber}
                  orderNumber={handover.orderNumber}
                  notes={cloneDeep(handover.notes) ?? []}
                >
                  <Notes
                    orderType={handover.orderData?.orderType ?? ''}
                    handover={handover}
                    withHandoverInfo
                  />
                </NotesContextProvider>
              )
            }
          >
            <Paragraph>{notesCount ? notesCount.toString() : EN_DASH}</Paragraph>
          </TextButton>
        </Styled.CenterItems>
      ),
      icon: undefined,
      borderColor: 'inherit',
      valueString: notesCount ? notesCount.toString() : EN_DASH
    };
  };

  const getNotesComments = (handover: HandoverTableData) => {
    const notesAsString = handover.notes
      ?.map(
        note =>
          `{${isNonEmptyString(note.type) ? `${text(note.type)} - ` : ''}${
            isNonEmptyString(note.title) ? `${note.title} - ` : ''
          }${note.text}${
            isNonEmptyString(note.addedBy) && isNullishOrEmpty(note.type)
              ? ` - ${note.addedBy}`
              : ''
          }}`
      )
      ?.join(' ');

    return {
      value: displayText(notesAsString),
      icon: undefined,
      borderColor: 'inherit',
      valueString: displayText(notesAsString)
    };
  };

  const getMarketAdaptionDate = (handover: HandoverTableData) => {
    const date = handover.orderData?.marketAdaptionDate;
    return {
      value: displayText(date),
      valueString: displayText(date)
    };
  };

  const getBatchId = (handover: HandoverTableData) =>
    handover.orderData?.handoverMethod === HandoverMethods.BULK_COLLECT ||
    handover.orderData?.handoverMethod === HandoverMethods.BULK_CUSTOMER_DELIVERY
      ? {
          value: displayText(handover.batch?.id),
          valueString: displayText(handover.batch?.id)
        }
      : NOT_APPLICABLE_CELL;

  const getPickupDate = (handover: HandoverTableData) => {
    const scheduledTime = getDateAndTimeString(
      handover?.batch?.scheduledPickupTime?.start,
      handover?.batch?.scheduledPickupTime?.end,
      handover.locationId
    );

    return handover.orderData?.handoverMethod === HandoverMethods.BULK_CUSTOMER_DELIVERY
      ? { value: displayText(scheduledTime), valueString: displayText(scheduledTime) }
      : NOT_APPLICABLE_CELL;
  };

  const ContactPerson = ({
    name,
    email,
    phone
  }: {
    name: string;
    email?: string;
    phone?: string;
  }) => (
    <>
      <Row mainAxisAlignment={MainAxisAlignments.spaceBetween}>
        <SharedStyle.ResponsivePageTitle level={2} style={{ alignSelf: 'end' }}>
          {text('ContactPerson')}
        </SharedStyle.ResponsivePageTitle>
      </Row>
      <Spacer spacing={{ mobile: Spacings.large, tablet: Spacings.xLarge }} />
      <Caption>{text('ContactDrawerInfo')}</Caption>
      <Spacer spacing={Spacings.xLarge} />
      <Caption>{text('Name')}</Caption>
      <Paragraph>{name}</Paragraph>
      <Spacer spacing={Spacings.xLarge} />
      <Caption>{text('Email')}</Caption>
      {isNonEmptyString(email) ? <Link href={`mailto:${email}`}>{email}</Link> : EN_DASH}
      <Spacer spacing={Spacings.xLarge} />
      <Caption>{text('PhoneNumber')}</Caption>
      {isNonEmptyString(phone) ? <Link href={`tel:${phone}`}>{phone}</Link> : EN_DASH}
    </>
  );

  const getContactPerson = (handover: HandoverTableData) => {
    const contactPerson = handover.orderData?.roleHolders?.find(x =>
      x.roles.includes('delivery_contact')
    );

    const name =
      isNonEmptyString(contactPerson?.firstName) && isNonEmptyString(contactPerson?.lastName)
        ? `${contactPerson?.firstName} ${contactPerson?.lastName}`
        : contactPerson?.firstName ?? contactPerson?.lastName ?? contactPerson?.name;
    return {
      value: (
        <>
          {isNonEmptyString(name) ? (
            <TextButton
              tabIndex={-1}
              onClick={() => {
                openDrawer(
                  <ContactPerson
                    name={name}
                    email={contactPerson?.email}
                    phone={contactPerson?.phone}
                  />
                );
              }}
              style={{
                textDecoration: 'underline',
                color: Colors.stormGrey,
                fontSize: '14px',
                lineHeight: '18px'
              }}
            >
              {name}
            </TextButton>
          ) : (
            EN_DASH
          )}
        </>
      ),
      valueString: displayText(name)
    };
  };

  const getEdition = (handover: HandoverTableData) => {
    const edition = displayText(handover.orderData?.edition);
    return {
      value: edition,
      valueString: edition
    };
  };

  const getBuyer = (handover: HandoverTableData) => {
    const buyer = handover.orderData?.roleHolders?.find(x => x.roles.includes('buyer'));
    const name = displayText(
      isNonEmptyString(buyer?.firstName) && isNonEmptyString(buyer?.lastName)
        ? `${buyer?.firstName} ${buyer?.lastName}`
        : buyer?.firstName ?? buyer?.lastName ?? buyer?.name
    );
    return {
      value: name,
      valueString: name
    };
  };

  const getHandoverPreparationStatus = (handover: HandoverTableData) => {
    return handover.handoverPreparationState === HandoverPreparationStatuses.COMPLETED
      ? COMPLETED_CELL
      : handover.handoverPreparationState === HandoverPreparationStatuses.IN_PROGRESS
      ? IN_PROGRESS_CELL
      : NOT_STARTED_CELL;
  };

  const getFeedbackComment = (handover: HandoverTableData) => {
    const feedbackComment = handover.feedback?.comment ? handover.feedback.comment : EN_DASH;
    return {
      value: feedbackComment,
      valueString: feedbackComment
    };
  };

  const getHasThumbsUp = (handover: HandoverTableData) => {
    const feedback = handover.feedback;
    return {
      value: feedback ? (feedback.hasThumbUp ? text('True') : text('False')) : EN_DASH,
      valueString: feedback ? (feedback.hasThumbUp ? text('True') : text('False')) : EN_DASH
    };
  };

  const getCompletedAt = (handover: HandoverTableData) => {
    const val = handover.orderData?.handoverCompletedAt
      ? formatDate(
          convertFromUtc(
            handover.orderData?.handoverCompletedAt,
            handoverOrderTimezone(handover.locationId)
          ),
          userLanguage,
          'DD MMM, YYYY'
        )
      : undefined;
    return {
      value: val ? val : EN_DASH,
      valueString: val ? val : EN_DASH
    };
  };
  const getCommercialDeliveryHoldStatus = (handover: HandoverTableData) => {
    const holdStatusString = handover.orderData?.commercialDeliveryHoldStatus
      ? 'Active'
      : 'Not Active';
    return {
      value: holdStatusString,
      valueString: holdStatusString
    };
  };
  const getModel = (handover: HandoverTableData) => {
    const model = displayText(handover.orderData?.model);
    return {
      value: model,
      valueString: model
    };
  };

  const getModelYear = (handover: HandoverTableData) => {
    const modelYear = displayText(handover.orderData?.modelYear);
    return {
      value: modelYear,
      valueString: modelYear
    };
  };

  const getPoNumber = (handover: HandoverTableData) => {
    const poNumber = displayText(handover.orderData?.purchaseOrderNumber);
    return {
      value: displayText(poNumber),
      valueString: displayText(poNumber)
    };
  };

  const getEarliestAllowedHandoverDate = (handover: HandoverTableData) => {
    const earliestAllowedHandoverDate = isNonEmptyString(
      handover.deliveryStatus?.earliestAllowedHandoverDate
    )
      ? formatDate(
          convertFromUtc(
            handover.deliveryStatus?.earliestAllowedHandoverDate,
            handoverOrderTimezone(handover.locationId)
          ),
          userLanguage,
          'YYYY-MM-DD'
        )
      : EN_DASH;
    return {
      value: earliestAllowedHandoverDate,
      valueString: earliestAllowedHandoverDate
    };
  };
  return { getColumnCell, renderCell };
};

export const getAvatar = (initials: string) => (
  <InternalAvatar
    style={{ maxHeight: '32px' }}
    size={Sizes.small}
    initials={initials}
    disabled={false}
    static
  />
);

export default useTableColumns;
